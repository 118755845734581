<template>
  <b-container fluid>
    <b-col md="12" class="my-1 p-0">
      <b-form-group>
        <b-row>
          <b-col md="5" lg="4"  class="py-3">
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="bg-white border-right-0 pr-0">
                  <svg style="width:20px;" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g clip-path="url(#clip0_15_152)"> <rect width="24" height="24" fill="white"></rect> <circle cx="10.5" cy="10.5" r="6.5" stroke="#000000" stroke-linejoin="round"></circle> <path d="M19.6464 20.3536C19.8417 20.5488 20.1583 20.5488 20.3536 20.3536C20.5488 20.1583 20.5488 19.8417 20.3536 19.6464L19.6464 20.3536ZM20.3536 19.6464L15.3536 14.6464L14.6464 15.3536L19.6464 20.3536L20.3536 19.6464Z" fill="#000000"></path> </g> <defs> <clipPath id="clip0_15_152"> <rect width="24" height="24" fill="white"></rect> </clipPath> </defs> </g></svg>
                </b-input-group-text>
              </template>
              <b-form-input
                id="filter-input"
                v-model="searchKeyword"
                type="search"
                placeholder="Search by Topic or Meeting ID"
                @input="handleFilterChange($event)"
                class="border-left-0"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-secondary">Go</b-button>
              </b-input-group-append>
            </b-input-group>


          </b-col>
          <div class="d-flex ml-auto p-3">
            <b-button class="m-1" variant="primary" disabled>
              Total Recordings <b-badge variant="light">{{ total }}</b-badge>
            </b-button>
            <b-button class="m-1" variant="success" disabled>
              Total Files <b-badge variant="light">{{ totalFiles }}</b-badge>
            </b-button>
            <b-button class="m-1" variant="info" @click="getAllVideos()" :disabled="lastCommand.status == 1 || lastCommand.status == 2 ? true : false">
              Check Zoom for Updates
            </b-button>
            <b-alert :show="refreshAllVideos" variant="success" class="mx-1 my-2">Command added to queue</b-alert>
          </div>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group label="Select User">
              <b-form-select  v-model="user" :options="users"
                              @change="getUserVideos($event)" class="m-0"
                              style="width: 300px;"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="3" class="m-0">
            <b-form-group label="From date">
              <b-form-datepicker id="example-datepicker-1" today-button
                reset-button
                close-button
                v-model="date1" @input="getVideosByDate1($event)" class="mb-2">
              </b-form-datepicker>
            </b-form-group>
            </b-col>
            <b-col md="3" class="m-0">
              <b-form-group label="To date">
                <b-form-datepicker id="example-datepicker-2"
                  today-button
                  reset-button
                  close-button
                  v-model="date2" @input="getVideosByDate2($event)" class="mb-2">
                </b-form-datepicker>
              </b-form-group>
            </b-col>
          <b-col md="3" class="m-0 text-right">
            <b-form-group label="Pagination">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                @change="handlePageSizeChange($event)"
                style="width:60px;"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>


      <b-table
        ref="table"
        id="my-table"
        :fields="fields"
        :filter="searchKeyword"
        :items="videos"
        :busy.sync="isBusy"
        :per-page="perPage"
        @sort-changed="sortingChanged"
        small
        borderless
        hover
      >
        <template #row-details="row">


          <b-table small :fields="fieldsSub" :items="row.item.recordings"  :striped="true" :bordered="false"
                   responsive="sm">

            <!-- A custom formatted column -->




            <template #cell(recording_start)="data">
              <b-col>{{formatDate(data.item.recording_start)}}</b-col>
            </template>

            <template #cell(recording_end)="data">
              <b-col>{{formatDate(data.item.recording_end)}}</b-col>
            </template>


            <template #cell(file_type)="data">
              <b-col>{{data.item.file_type}}</b-col>
            </template>


            <template #cell(file_extension)="data">
              <b-col>{{data.item.file_extension}}</b-col>
            </template>

            <template #cell(file_size)="data">
              <b-col>{{data.item.file_size}}</b-col>
            </template>

            <template #cell(status)="data">
              <b-col>{{data.item.status}}</b-col>
            </template>

            <template #cell(recording_type)="data">
              <b-col>{{data.item.recording_type}}</b-col>
            </template>

            <template #cell(uploaded_size)="data" >
              <b-col :ref="'size_' + data.item.id.replaceAll('-','_')">{{data.item.uploaded_size}}</b-col>
            </template>

            <template #cell(copy_status)="data" >
              <b-col :ref="'status_' + data.item.id.replaceAll('-','_')" :class="data.item.copy_status == 3 ? 'text-success' : ''">
                <span v-if="data.item.copy_status === 0">Not copied</span>
                <span v-if="data.item.copy_status === 1">Queued</span>
                <span v-if="data.item.copy_status === 2">Started</span>
                <span v-if="data.item.copy_status === 3">Completed</span>
                <span v-if="data.item.copy_status === 4">Failed</span>
                <span v-if="data.item.copy_status === 5">Ignored</span>
                <span v-if="data.item.copy_status === 6">Skipped</span>
              </b-col>
            </template>

            <template #cell(actions)="data">
              <b-button size="sm" v-if="data.item.copied === 1 && (data.item.file_extension === 'MP4' || data.item.file_extension === 'M4A')" variant="primary" :ref="'play_' + data.item.id.replaceAll('-','_')" class="mx-1 my-2"  @click="openShareUrl(data.item.id,  data.item.file_type + ' ' + data.item.recording_type, $event.target)">Play</b-button>
              <b-button size="sm" v-if="data.item.copied === 1" variant="outline-dark" :ref="'download_' + data.item.id.replaceAll('-','_')" class="mx-1 my-2" @click="getShareUrl(data.item.id,  data.item.file_type + ' ' + data.item.recording_type, $event.target)">Share URL</b-button>
              <b-button size="sm" variant="dark" v-show="data.item.status == 'completed' && data.item.status !== 'deleted' && (data.item.copy_status === 0 || data.item.copy_status === 3 || data.item.copy_status === 4)" @click="showRecordingCopyModal(data.item.id,  data.item.file_type + ' ' + data.item.file_extension, $event.target)" class="mx-1 my-2">Re-Copy from Zoom</b-button>
              <b-alert :show="success == data.item.id" variant="success" class="mx-1 my-2">Added to queue</b-alert>
              <b-alert :show="error == data.item.id" variant="danger" class="m-1 my-2">{{ errorText }}</b-alert>
              <b-alert :show="share == data.item.id" variant="danger" class="m-1 my-2">Share Url is copied in clipboard</b-alert>
              <b-button size="sm" @click="showModal(data.item.id)" class="mr-2">
                Events Log
              </b-button>
          </template>
          </b-table>
          <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
          <b-button size="sm" variant="info" @click="refreshRow(row.item.id)" class="mx-2">Refresh copy status</b-button>
        </template>

        <template #cell(actions)="row">
          <b-button size="sm" @click="row.toggleDetails" class="mr-2">
            {{ row.detailsShowing ? 'Hide' : 'Show'}} Recordings
          </b-button>
          &nbsp;
          <b-button size="sm" variant="info" v-if="row.item.show_copy_all" @click="showRecordingCopyAllModal(row.item.id,  row.item.topic, $event.target)">Copy All</b-button>
          <b-alert :show="success == row.item.id" variant="success">Added to queue</b-alert>
          <b-alert :show="error == row.item.id" variant="danger">{{ errorText }}</b-alert>
          <b-alert :show="share == row.item.id" variant="success">{{ url }}</b-alert>
        </template>
      </b-table>

      <b-row>
        <b-col md="6" class="overflow-auto">
          <b-pagination
          v-if="total > 0"
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="my-table"
            @change="handlePageChange"
          ></b-pagination>
      </b-col>
      <b-col md="3" offset-md="3">
        <b-alert show variant="success">
          <h6 class="alert-heading text-sm">Update Status From Zoom:</h6>
          <b-text style="font-size:12px">
              <span v-if="lastCommand.status == 0">Not Running</span>
              <span v-if="lastCommand.status == 1">Set in the queue</span>
              <span v-if="lastCommand.status == 2">Running</span>
              <span v-if="lastCommand.status == 3">Done</span>
            </b-text>
          <hr class="my-2">
          <b-text style="font-size:12px" class="mb-0">Last run: {{lastCommand.last_run_at != null ? formatDate(lastCommand.last_run_at) : 'Never'}}</b-text>
        </b-alert>
      </b-col>
    </b-row>
  </b-col>

    <b-modal :id="infoModal.id" :uuid="infoModal.uuid" :title="infoModal.title" cancel-title="No" ok-title="Yes"
             ok-variant="danger" @hide="resetRecordingDeleteModal" @ok="deleteRecordingModal">
      {{ infoModal.content }}
    </b-modal>

    <b-modal :id="infoModalCopy.id" :uuid="infoModalCopy.uuid" :title="infoModalCopy.title" cancel-title="No"
             ok-title="Yes" ok-variant="danger" @hide="resetRecordingCopyModal" @ok="copyRecordingModal">
      {{ infoModalCopy.content }}

      <small>The video will be added to the queue.</small>
    </b-modal>

    <b-modal :id="infoModalCopyAll.id" :uuid="infoModalCopyAll.uuid" :title="infoModalCopyAll.title" cancel-title="No"
             ok-title="Yes" ok-variant="danger" @hide="resetRecordingCopyAllModal" @ok="copyRecordingAllModal">
      {{ infoModalCopyAll.content }}

      <small>The video will be added to the queue.</small>
    </b-modal>


    <b-modal id="modal-1" title="Events Log" ok-only size="lg">
      &nbsp;<b-button @click="getRecordingEvents()" class="mr-2">
      Refresh
    </b-button>


      <b-table small :fields="fieldsSubModal" :items="events" class="text-break items-details" :striped="true" :bordered="true"
               responsive="sm">

        <template #cell(status)="data">
          <div class="mb-2">Date: {{formatDate(data.item.created_at)}}</div>
          <div class="mb-2">Status:
            <span v-if="data.item.status == 1">Added to queue</span>
            <span v-if="data.item.status == 2">Failed download</span>
            <span v-if="data.item.status == 3">Started download</span>
            <span v-if="data.item.status == 4">Upload failed</span>
            <span v-if="data.item.status == 5">Copy finished</span>
            <span v-if="data.item.status == 6">Error upload</span>
            <span v-if="data.item.status == 7">Deleted in zoom</span>
            <span v-if="data.item.status == 8">Deleted in zoom when in queue</span>
            <span v-if="data.item.status == 9">Zoom unauthorised</span>
          </div>
          <div v-if="data.item.text != ''">Message: {{data.item.text}}</div>

        </template>

      </b-table>
    </b-modal>
  </b-container>
</template>

<script>

import {mapActions, mapState,} from 'vuex';
//import moment from 'moment'
import moment from 'moment-timezone'

export default {
  data() {
    return {
      items: [
        {
          text: 'Recordings',
          active: true
        }
      ],
      fields: [
        {
          key: 'video_id',
          label: 'Meeting ID',
        },
        {
          key: 'display_name',
          label: 'User',
          sortable: true,
        },
        {
          key: 'topic',
          sortable: true,
        },
        {
          key: 'type',
          sortable: false,
          formatter: (value) => {
            if(value == '1') {
              return 'Instant meeting'
            }
            if(value == '2') {
              return 'Scheduled meeting'
            }
            if(value == '3') {
              return 'A recurring meeting with no fixed time'
            }
            if(value == '4') {
              return 'A meeting created via PMI (Personal Meeting ID)'
            }
            if(value == '5') {
              return 'Webinar'
            }
            if(value == '6') {
              return 'A recurring webinar without a fixed time'
            }
            if(value == '7') {
              return ' A Personal Audio Conference (PAC)'
            }
            if(value == '8') {
              return 'Recurring meeting with a fixed time'
            }
            if(value == '9') {
              return 'A recurring webinar with a fixed time'
            }
          }
        },
        {
          key: 'start_time',
          sortable: true,
          formatter: (value) => {
            return moment(value).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm:ss')
          }
        },
        {
          key: 'duration',
          formatter: (value) => {
            return value + 'min'
          }
        },
        {
          key: 'total_size',
        },
        {
          key: 'recording_count',
        },
        {
          key: 'completed',
        },
        {
          key: 'actions',
          label: 'Actions',
        }
      ],
      fieldsSub: [


        {
          key: 'recording_start',
          label: 'Recording Start',
          sortable: false,
        },
        {
          key: 'recording_end',
          label: 'Recording End',
          sortable: false,
        },
        {
          key: 'file_type',
          label: 'File Type',
          sortable: false,
        },
        {
          key: 'file_size',
          label: 'File Size',
          sortable: false,
        },
        {
          key: 'status',
          label: 'Zoom Status',
          sortable: false,
        },
        {
          key: 'recording_type',
          label: 'Recording Type',
          sortable: false,
        },
        {
          key: 'uploaded_size',
          label: 'Copied Size',
          sortable: false,
        },
        {
          key: 'copy_status',
          label: 'Copy status',
          sortable: false
        },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false,
        },

          ],
      fieldsSubModal: [


        {
          key: 'status',
          label: 'Event',
          sortable: false,
        },


      ],
      pageOptions: [10, 20, 30],
      perPage: 20,
      page: 1,
      total: 0,
      isBusy: false,
      searchKeyword: '',
      striped: true,
      filter: false,
      sortBy: '',
      sortDirection: '',
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      infoModalCopy: {
        id: 'info-modal-copy',
        title: '',
        content: ''
      },
      infoModalCopyAll: {
        id: 'info-modal-copy-all',
        title: '',
        content: ''
      },
      user: 'all',
      success: '',
      users: [],
      error: '',
      errorText: '',
      share: '',
      url: '',
      videos: [],
      date1: '',
      date2: '',
      totalFiles: 0,
      events: [],
      recordingId: '',
      refreshAllVideos: false,
      lastCommand: []

    }
  },
  created() {

  },
  mounted(){
    this.getUsersLocal()
    this.getLocalVideos()
  },

  methods: {
    ...mapActions(['getVideos', 'copyRecording', 'copyAllRecording', 'shareUrl', 'getRecordingUsers', 'deleteVideo', 'refreshVideo', 'getEvents', 'refreshVideos']),
    ...mapState(['getVideosState', 'getVideosTotal', 'getVideosPage']),

    getRequestParams() {
      let params = {};

      let uri = window.location.search.substring(1);
      let queryParams = new URLSearchParams(uri);

      if(queryParams.get("keyword")) {
        params["keyword"] = queryParams.get("keyword")
      }

      if (this.searchKeyword) {
        params["keyword"] = this.searchKeyword;
      }

      this.searchKeyword = params["keyword"]


      if(queryParams.get("page")) {
        params["page"] = queryParams.get("page")
        this.page = params["page"]
      }




      if (this.perPage) {
        params["perPage"] = this.perPage;
      }

      if(queryParams.get("perPage")) {
        params["perPage"] = queryParams.get("perPage")
      }

      this.perPage = params["perPage"]

      if(queryParams.get("sort_by")) {
        params["sortBy"] = queryParams.get("sort_by");
      }

      if (this.sortBy) {
        params["sortBy"] = this.sortBy;
      }

      this.sortBy = params["sortBy"]


      if(queryParams.get("sort_direction")) {
        params["sort_direction"] = queryParams.get("sort_direction")
      }


      if (this.sortDirection) {
        params["sortDirection"] = this.sortDirection;
      }

      this.sortDirection = params["sortDirection"]


      if (this.user) {
        params["user"] = this.user;
      }

      if(queryParams.get("user")) {
        params["user"] = queryParams.get("user")
      }


      this.user = params["user"]

      if(queryParams.get("from")) {
        params["from"] = queryParams.get("from");
      }

      if (this.date1 != '') {
        params["from"] = this.date1;
      }

      if(params["from"] != undefined && params["from"] != 'undefined' && params["from"] != '') {
        this.date1 = params["from"]
      }


      if(queryParams.get("to")) {
        params["to"] = queryParams.get("to");
      }

      if (this.date2) {
        params["to"] = this.date2;
      }

      if(params["to"] != '') {
        this.date2 = params["to"]
      }

      return params;
    },
    getUsersLocal() {

      return this.getRecordingUsers()
          .then((response) => {
             this.users = response.data.users
          })
    },
    formatDate(value) {
        return moment(value).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm:ss')
    },
    getLocalVideos() {
        const params = this.getRequestParams(
            this.searchKeyword,
            this.page,
            this.perPage,
            this.sortBy,
            this.sortDirection
        );
         this.getVideos(params)
            .then((response) => {
              this.total = response.data.total
              this.totalFiles = response.data.total_files
              this.videos = response.data.videos
              this.lastCommand = response.data.last_command
              this.page = response.data.page
            })
    },
    getUserVideos(value) {

      if(value != 'all') {

        this.user = value
      }

      const uri = window.location.search.substring(1);

      const queryParams = new URLSearchParams(uri);
      queryParams.delete('user')
      if(value != 'all') {
        queryParams.append('user', value)
      }

      window.history.replaceState(null, '', '?' + queryParams.toString());

      const params = this.getRequestParams(
          this.searchKeyword,
          this.page,
          this.perPage,
          this.sortBy,
          this.sortDirection,
          value
      );

      this.getVideos(params)
            .then((response) => {
              this.total = response.data.total
              this.totalFiles = response.data.total_files
              this.videos = response.data.videos
              this.lastCommand = response.data.last_command
              this.page = response.data.page
            })
    },
    sortingChanged(ctx) {


      this.sortBy = ctx.sortBy
      this.sortDirection = ctx.sortDesc ? 'desc' : 'asc'

      const uri = window.location.search.substring(1);

      const queryParams = new URLSearchParams(uri);
      queryParams.delete('sort_by')
      queryParams.delete('sort_dir')
      queryParams.append('sort_by',  this.sortBy)
      queryParams.append('sort_dir',  this.sortDirection)

      window.history.replaceState(null, '', '?' + queryParams.toString());

      const params = this.getRequestParams(
          this.searchKeyword,
          this.page,
          this.perPage,
          this.sortBy,
          this.sortDirection,
          this.user
      );


       this.getVideos(params)
        .then((response) => {
          this.page = response.data.page
          this.total = response.data.total
          this.videos = response.data.videos
          this.lastCommand = response.data.last_command

        })
    },
    handlePageChange(value) {
      this.page = value
      const uri = window.location.search.substring(1);

      const queryParams = new URLSearchParams(uri);
      queryParams.delete('page')
      queryParams.append('page',  this.page)

      window.history.replaceState(null, '', '?' + queryParams.toString());

      const params = this.getRequestParams(
          this.searchKeyword,
          this.page,
          this.perPage,
          this.sortBy,
          this.sortDirection,
          this.user
      );
      this.getVideos(params)

        .then((response) => {
          this.page = response.data.page
          this.total = response.data.total
          this.videos = response.data.videos
          this.lastCommand = response.data.last_command
        })
    },
    handlePageSizeChange(value) {

      this.perPage = value


      const uri = window.location.search.substring(1);

      const queryParams = new URLSearchParams(uri);
      queryParams.delete('perPage')
      queryParams.append('perPage',  value)

      window.history.replaceState(null, '', '?' + queryParams.toString());

      const params = this.getRequestParams(
          this.searchKeyword,
          1,
          value,
          this.sortBy,
          this.sortDirection,
          this.user
      );

       this.getVideos(params)
        .then((response) => {
          this.total = response.data.total
          this.totalFiles = response.data.total_files
          this.videos = response.data.videos
          this.lastCommand = response.data.last_command
        })
    },
    getVideosByDate1(value) {

      this.date1 = value



      const uri = window.location.search.substring(1);

      const queryParams = new URLSearchParams(uri);
      queryParams.delete('from')
      if(value != '') {
        queryParams.append('from', value)
      }

      window.history.replaceState(null, '', '?' + queryParams.toString());

      const params = this.getRequestParams(
          this.searchKeyword,
          this.page,
          this.perPage,
          this.sortBy,
          this.sortDirection,
          this.user
      );

      this.getVideos(params)
          .then((response) => {
            this.page = response.data.page
            this.total = response.data.total
            this.totalFiles = response.data.total_files
            this.videos = response.data.videos
            this.lastCommand = response.data.last_command
          })
    },
    getVideosByDate2(value) {
      this.date2 = value


      const uri = window.location.search.substring(1);

      const queryParams = new URLSearchParams(uri);
      queryParams.delete('to')
      if(value != '') {
        queryParams.append('to', value)
      }

      window.history.replaceState(null, '', '?' + queryParams.toString());


      const params = this.getRequestParams(
          this.searchKeyword,
          this.page,
          this.perPage,
          this.sortBy,
          this.sortDirection,
          this.user
      );

      this.getVideos(params)
          .then((response) => {
            this.page = response.data.page
            this.total = response.data.total
            this.totalFiles = response.data.total_files
            this.videos = response.data.videos
            this.lastCommand = response.data.last_command
          })
    },
    handleFilterChange() {
      this.page = 1

      const uri = window.location.search.substring(1);

      const queryParams = new URLSearchParams(uri);
      queryParams.delete('keyword')
      if(this.searchKeyword != '') {
        queryParams.append('keyword', this.searchKeyword)
      }

      queryParams.delete('page')
      queryParams.append('page',  this.page)

      window.history.replaceState(null, '', '?' + queryParams.toString());

      const params = this.getRequestParams(
          this.searchKeyword,
          this.page,
          this.perPage,
          this.sortBy,
          this.sortDirection,
          this.user
      );

      this.getVideos(params)
        .then((response) => {
          this.page = response.data.page
          this.total = response.data.total
          this.totalFiles = response.data.total_files
          this.videos = response.data.videos
          this.lastCommand = response.data.last_command
        })
    },
    showRecordingDeleteModal(uuid, title, button) {
      this.infoModal.uuid = uuid
      this.infoModal.title = `Confirmation`
      this.infoModal.content = `Are you sure you want to delete the video "${title}"?`
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetRecordingDeleteModal() {
      this.infoModal.uuid = ''
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    showRecordingCopyModal(uuid, title, button) {
      this.infoModalCopy.uuid = uuid
      this.infoModalCopy.title = `Confirmation`
      this.infoModalCopy.content = `Are you sure you want to make a copy of the file "${title}"?`
      this.$root.$emit('bv::show::modal', this.infoModalCopy.id, button)
    },
    showRecordingCopyAllModal(uuid, title, button) {
      this.infoModalCopyAll.uuid = uuid
      this.infoModalCopyAll.title = `Confirmation`
      this.infoModalCopyAll.content = `Are you sure you want to make a copy of all the files in "${title}"?`
      this.$root.$emit('bv::show::modal', this.infoModalCopyAll.id, button)
    },
    resetRecordingCopyModal() {
      //this.infoModalCopy.uuid = ''
      //this.infoModalCopy.title = ''
      //this.infoModalCopy.content = ''
    },
    resetRecordingCopyAllModal() {
      //this.infoModalCopyAll.uuid = ''
      //this.infoModalCopyAll.title = ''
      //this.infoModalCopyAll.content = ''
    },
    refreshRow(uuid) {

this.refreshVideo(uuid)
    .then((response) => {

      /*let thisObj = this;
      response.data.forEach((value) => {
        var copy_status = '';

        if(value.copy_status == 0) {
          copy_status = 'Not copied';
        }
        if(value.copy_status == 1) {
          copy_status = 'Queued';
        }
        if(value.copy_status == 2) {
          copy_status = 'Started';
        }
        if(value.copy_status == 3) {
          copy_status = '<span class="text-success">Completed</span>';
        }

        thisObj.$refs["status_" + value.id.replaceAll('-','_')].innerHTML = copy_status
        thisObj.$refs["size_" + value.id.replaceAll('-','_')].innerHTML = value.uploaded_size
        if(value.copy_status == 3 && value.file_extension == 'MP4' || value.file_extension == 'M4A') {
          thisObj.$refs["download_" + value.id.replaceAll('-', '_')].classList.add('d-inline-block')
          thisObj.$refs["play_" + value.id.replaceAll('-', '_')].classList.add('d-inline-block')

        }
      });*/
      const foundVideo = this.videos.find(obj => obj.id === uuid);
      console.log(foundVideo)
      if (foundVideo) {
        foundVideo.recordings.forEach((recording) => {
          // Find the corresponding item in the new values array
          const matchingRecording = response.data.find((item) => item.id === recording.id);

          // If a matching item is found, update the properties
          if (matchingRecording) {
            console.log(matchingRecording.id, recording.id)
            recording.copy_status = matchingRecording.copy_status;
            recording.file_extension = matchingRecording.file_extension;
            recording.uploaded_size = matchingRecording.uploaded_size;
          }
        });
      }
    })

     // this.$root.$emit('bv::show::modal', this.infoModalCopy.id, button)
    },
    deleteRecordingModal() {
      this.deleteRecording(this.infoModal.uuid)
      this.$refs.table.refresh();
    },
    copyRecordingModal() {
      this.copyRecording(this.infoModalCopy.uuid)
          .then((response) => {
           if(response.data.status) {
             this.success = this.infoModalCopy.uuid
           }
           else {
             this.error = this.infoModalCopy.uuid
             this.errorText = response.data.error
           }

          })
    },
    copyRecordingAllModal() {
      this.copyAllRecording(this.infoModalCopyAll.uuid)
          .then((response) => {
            if(response.data.status) {
              this.success = this.infoModalCopyAll.uuid
            }
            else {
              this.error = this.infoModalCopy.uuid
              this.errorText = response.data.error
            }

          })
    },
    async getShareUrl(id) {
      this.shareUrl(id)
          .then(async (response) => {
            if(response.data.status) {
              this.url = response.data.url

                try {
                  await navigator.clipboard.writeText(this.url);
                  this.share = id

                 // alert('Copied');
                  setTimeout(() => this.share = false, 3000)

                } catch($e) {
                  //alert('Cannot copy');
                }
              }
          })
    },
    async openShareUrl(id) {
      this.shareUrl(id)
          .then(async (response) => {
            if(response.data.status) {
              window.open(response.data.url, "_blank");
            }
          })
    },
    getRecordingEvents() {


      this.getEvents(this.recordingId)
          .then((response) => {
            this.events = response.data.events
          })

    },
    getAllVideos() {

this.lastCommand.status = 1
      this.refreshVideos()
          .then((response) => {
            if(response.status) {

              this.refreshAllVideos = true;

              setTimeout(() => this.refreshAllVideos = false, 3000)

            }
          })

    },
    showModal(id) {
      this.recordingId = id
      this.getEvents(id)
          .then((response) => {
            this.events = response.data.events
          })
      this.$root.$emit('bv::show::modal', 'modal-1')
    },
  },
}
</script>

<style>
.breadcrumb {
  background-color: #d1ecf1;
}
</style>
